import * as React from 'react';
import { QueryClient, QueryClientProvider, QueryFunction } from 'react-query';
import { apiClient } from 'Shared/api_client';

/**
 * This default query function allows us to use the `useQuery` hook with only the API route
 * Example:
 *    const { data, isLoading, error } = useQuery('guides/123');
 */
const defaultQueryFn: QueryFunction<unknown, string> = async ({ queryKey }) => {
   const endpoint = queryKey[0];
   const response = await apiClient.get(endpoint);
   return response.data;
};

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         queryFn: defaultQueryFn,
      },
   },
});

export function initializeReactQuery(component: React.ReactElement): React.ReactElement {
   return <QueryClientProvider client={queryClient}>{component}</QueryClientProvider>;
}
