import { ApiClient } from '@dozuki/web-js/utils';

declare const App: {
   useSecureCookies?: boolean;
   sameSiteValue?: 'Lax' | 'None' | 'Strict';
};

export const apiClient = new ApiClient({
   csrfCookieOptions: {
      secure: App.useSecureCookies,
      sameSite: App.sameSiteValue,
   },
});
